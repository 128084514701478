// import { ChainId, Token } from '@pancakeswap/sdk'
import { USDT_ROBIN_TESTNET, BTC_ROBIN, CAKE_ROBIN, WRPG_TESTNET } from './common'

export const robTestnetTokens = {
  // weth: WETH9[ChainId.ROBIN_TESTNET],
  cake: CAKE_ROBIN,
  btc: BTC_ROBIN,
  usdt: USDT_ROBIN_TESTNET,
  rpg: WRPG_TESTNET,
  // bnb: new Token(ChainId.ROBIN_TESTNET, '0x5ff44DDb43f23aC6b9D19E986d71563191d236CE', 18, 'BNB', 'Mock BNB', ''),
  // matic: new Token(ChainId.ROBIN_TESTNET, '0xdcc66b82517F688FE09450ddDED3B082a6E789af', 18, 'MATIC', 'Mock MATIC', ''),
  // ada: new Token(ChainId.ROBIN_TESTNET, '0x53086aB50b6c5bB42597759FD53c177FA70718FC', 18, 'ADA', 'Mock ADA', ''),
  // sol: new Token(ChainId.ROBIN_TESTNET, '0xe2f3B07642D7be37ed3e27e42360f48b464E881a', 18, 'SOL', 'Mock SOL', ''),
}
