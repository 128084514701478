import BigNumber from 'bignumber.js'
// import fromPairs from 'lodash/fromPairs'
// import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
// import poolsConfig from 'config/constants/pools'
import statkeTokenABI from 'config/abi/stakeToken.json'
// import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
// import { getAddress } from 'utils/addressHelpers'
// import { BIG_ZERO } from 'utils/bigNumber'
// import chunk from 'lodash/chunk'
// import { getBalanceNumber } from 'utils/formatBalance'
// import sousChefV2 from 'config/abi/sousChefV2.json'
// import sousChefV3 from 'config/abi/sousChefV3.json'
import { TOKEN_TOP } from 'config/constants/endpoints'
import qs from 'qs'

export const fetchPoolsBlockLimits = async (poolsConfig) => {
  // console.log('fetchPoolsBlockLimits', pools)
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0)
  const startEndBlockCalls = poolsWithEnd.flatMap((poolConfig) => {
    // console.log('poolConfig', poolConfig)
    return [
      {
        address: poolConfig.contractAddress,
        name: 'startBlock',
      },
      {
        address: poolConfig.contractAddress,
        name: 'endBlock',
      },
    ]
  })

  const startEndBlockRaw = await multicall(statkeTokenABI, startEndBlockCalls)

  const startEndBlockResult = startEndBlockRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2)

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return poolsWithEnd.map((poolConfig, index) => {
    const [[startBlock], [endBlock]] = startEndBlockResult[index]
    return {
      sousId: poolConfig.sousId,
      startBlock: startBlock.toNumber(),
      endBlock: endBlock.toNumber(),
    }
  })
}

// const poolsBalanceOf = poolsConfig.map((poolConfig) => {
//   return {
//     address: poolConfig.stakingToken.address,
//     name: 'balanceOf',
//     params: [poolConfig.contractAddress],
//   }
// })

export const fetchPoolsTotalStaking = async (poolsConfig) => {
  const getInfoCalls = poolsConfig.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress,
      name: 'getInfo',
      params: [poolConfig.sousId],
    }
  })
  // const poolsTotalStaked = await multicall(erc20ABI, poolsBalanceOf)
  const poolsTotalStaked = await multicall(statkeTokenABI, getInfoCalls)
  // console.log('fetchPoolsTotalStaking', poolsTotalStaked[0][4]?.toString())
  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    totalStaked: new BigNumber(poolsTotalStaked[index][4]?.toString()).toJSON(),
    // totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
  }))
}

export const fetchPoolsStakingLimits = async (poolsConfig) => {
  const getInfoCalls = poolsConfig.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress,
      name: 'getInfo',
      params: [poolConfig.sousId],
    }
  })
  const poolStakingCalls = await multicall(statkeTokenABI, getInfoCalls)
  // console.log(
  //   'fetchPoolsStakingLimits',
  //   getBalanceNumber(poolStakingCalls[0][2]?.toString()),
  //   getBalanceNumber(poolStakingCalls[0][3]?.toString()),
  //   getBalanceNumber(poolStakingCalls[0][4]?.toString()),
  //   poolStakingCalls[0][5]?.toString(),
  //   getBalanceNumber(poolStakingCalls[0][6]?.toString()),
  // )

  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    stakingLimit: new BigNumber(poolStakingCalls[index][6]?.toString()).toJSON(),
    numberBlocksForUserLimit: new BigNumber(poolStakingCalls[index][2]?.toString()).toJSON(),
    // totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
  }))
}

// get pools token prices
export const fetchPoolsTokenPrices = async () => {
  const params = qs.stringify({
    page: 1,
    page_size: 50,
    order: 'volumeUSD',
    is_asc: false, // false代表倒序，true代表正序
  })
  let data = []
  try {
    const res = await fetch(`${TOKEN_TOP}?${params}`)
    const resData = await res.json()
    if (resData?.status === 200) {
      data = resData.data.content
      // console.log('fecth top tokens data===', resData?.data)
    }
  } catch (error) {
    data = []
    console.error('Unable to fetch data:', error)
  }
  return data
}

// sousChefV2
// export const fetchPoolsStakingLimits2 = async (
//   poolsWithStakingLimit: number[],
// ): Promise<{ [key: string]: { stakingLimit: BigNumber; numberBlocksForUserLimit: number } }> => {
//   const validPools = poolsConfig
//     // .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
//     .filter((p) => !poolsWithStakingLimit.includes(p.sousId))

//   // Get the staking limit for each valid pool
//   const poolStakingCalls = validPools
//     .map((validPool) => {
//       const contractAddress = getAddress(validPool.contractAddress)
//       return ['hasUserLimit', 'poolLimitPerUser', 'numberBlocksForUserLimit'].map((method) => ({
//         address: contractAddress,
//         name: method,
//       }))
//     })
//     .flat()

//   const poolStakingResultRaw = await multicallv2({
//     abi: sousChefV2,
//     calls: poolStakingCalls,
//     options: { requireSuccess: false },
//   })
//   const chunkSize = poolStakingCalls.length / validPools.length
//   const poolStakingChunkedResultRaw = chunk(poolStakingResultRaw.flat(), chunkSize)
//   return fromPairs(
//     poolStakingChunkedResultRaw.map((stakingLimitRaw, index) => {
//       const hasUserLimit = stakingLimitRaw[0]
//       const stakingLimit = hasUserLimit && stakingLimitRaw[1] ? new BigNumber(stakingLimitRaw[1].toString()) : BIG_ZERO
//       const numberBlocksForUserLimit = stakingLimitRaw[2] ? (stakingLimitRaw[2] as EthersBigNumber).toNumber() : 0
//       return [validPools[index].sousId, { stakingLimit, numberBlocksForUserLimit }]
//     }),
//   )
// }

// const poolsWithV3 = poolsConfig.filter((pool) => pool?.version === 3)

// export const fetchPoolsProfileRequirement = async (): Promise<{
//   [key: string]: {
//     required: boolean
//     thresholdPoints: string
//   }
// }> => {
//   const poolProfileRequireCalls = poolsWithV3
//     .map((validPool) => {
//       const contractAddress = getAddress(validPool.contractAddress)
//       return ['pancakeProfileIsRequested', 'pancakeProfileThresholdPoints'].map((method) => ({
//         address: contractAddress,
//         name: method,
//       }))
//     })
//     .flat()

//   const poolProfileRequireResultRaw = await multicallv2({
//     abi: sousChefV3,
//     calls: poolProfileRequireCalls,
//     options: { requireSuccess: false },
//   })
//   const chunkSize = poolProfileRequireCalls.length / poolsWithV3.length
//   const poolStakingChunkedResultRaw = chunk(poolProfileRequireResultRaw.flat(), chunkSize)
//   return fromPairs(
//     poolStakingChunkedResultRaw.map((poolProfileRequireRaw, index) => {
//       const hasProfileRequired = poolProfileRequireRaw[0]
//       const profileThresholdPoints = poolProfileRequireRaw[1]
//         ? new BigNumber(poolProfileRequireRaw[1].toString())
//         : BIG_ZERO
//       return [
//         poolsWithV3[index].sousId,
//         {
//           required: !!hasProfileRequired,
//           thresholdPoints: profileThresholdPoints.toJSON(),
//         },
//       ]
//     }),
//   )
// }
