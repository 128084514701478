import BigNumber from 'bignumber.js'
import { DeserializedPool, SerializedPool } from 'state/types'
import { deserializeToken } from '@pancakeswap/tokens'
import { BIG_ZERO } from 'utils/bigNumber'

type UserData =
  | DeserializedPool['userData']
  | {
      allowance: number | string
      stakingTokenBalance: number | string
      stakedBalance: number | string
      pendingReward: number | string
    }

export const transformUserData = (userData: UserData) => {
  return {
    allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
    stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
    stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
    pendingReward: userData ? new BigNumber(userData.pendingReward) : BIG_ZERO,
  }
}

export const transformPool = (nft: SerializedPool): DeserializedPool => {
  const { totalStaked, userData, stakingToken, earningToken, stakingLimit, profileRequirement, ...rest } = nft

  return {
    ...rest,
    stakingToken: deserializeToken(stakingToken),
    earningToken: deserializeToken(earningToken),
    userData: transformUserData(userData),
    totalStaked: new BigNumber(totalStaked),
  }
}

export const getTokenPricesFromTopToken = (nfts: any[]) => {
  return nfts.reduce((prices, nft) => {
    // console.log('pool==', pool)
    const tokenAddress = nft.address.toLowerCase()
    /* eslint-disable no-param-reassign */
    if (!prices[tokenAddress]) {
      prices[tokenAddress] = new BigNumber(nft.priceUSD).toNumber()
    }
    /* eslint-enable no-param-reassign */
    return prices
  }, {})
}
