export const TOKENS_LIST = `${process.env.NEXT_PUBLIC_URL_SERVICE}/file/tokenlist.json?t=${Date.now()}`

// List of official tokens list
export const OFFICIAL_LISTS = [TOKENS_LIST]

export const UNSUPPORTED_LIST_URLS: string[] = []
export const WARNING_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [TOKENS_LIST]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = []
