import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  margin-right: 30px;\n  ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  display: flex;\n  align-items: center;\n  color: ",
        ";\n  font-size: 16px;\n  font-weight: ",
        ";\n  opacity: ",
        ";\n  background-image: ",
        ";\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: ",
        ";\n\n  ",
        "\n\n  ",
        "\n\n  ",
        "\n\n  &:hover {\n    background: linear-gradient(270deg, #50d0b7 0%, #00c3ff 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    // color: ",
        ";\n    // background: ",
        ";\n    // ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var StyledMenuItemContainer = styled.div.withConfig({
    componentId: "sc-c8d13359-0"
})(_templateObject(), function(param) {
    var $isActive = param.$isActive, $variant = param.$variant, theme = param.theme;
    return $isActive && $variant === "subMenu" && '\n      &:after{\n        content: "";\n        position: absolute;\n        bottom: 0;\n        height: 4px;\n        width: 100%;\n        background-color: '.concat(theme.colors.primary, ";\n        border-radius: 2px 2px 0 0;\n      }\n    ");
});
var navBorder = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTNweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgOTMgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+c3dhcF9pY29uX21pbmluZzwvdGl0bGU+CiAgICA8ZGVmcz4KICAgICAgICA8bGluZWFyR3JhZGllbnQgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSIgaWQ9ImxpbmVhckdyYWRpZW50LTEiPgogICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjMEFDNUY3IiBvZmZzZXQ9IjAlIj48L3N0b3A+CiAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiM1MEQwQjciIG9mZnNldD0iMTAwJSI+PC9zdG9wPgogICAgICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgICA8L2RlZnM+CiAgICA8ZyBpZD0i6aG16Z2iLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSLmjJbnn78iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05OTEuMDAwMDAwLCAtNTAuMDAwMDAwKSIgc3Ryb2tlPSJ1cmwoI2xpbmVhckdyYWRpZW50LTEpIj4KICAgICAgICAgICAgPGcgaWQ9Iue8lue7hC0xNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTg3LjAwMDAwMCwgMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJGcmFtZS00NSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzUwLjAwMDAwMCwgNDEuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTUzNC41MzAzOCwxMCBMNTM3LjQ2ODc1LDEwIEM1NDIuMTgwNDI5LDEwIDU0NiwxMy4zNTc4NjQ0IDU0NiwxNy41IEM1NDYsMjEuNjQyMTM1NiA1NDIuMTgwNDI5LDI1IDUzNy40Njg3NSwyNSBMNDYzLjUzMTI1LDI1IEM0NTguODE5NTcxLDI1IDQ1NSwyMS42NDIxMzU2IDQ1NSwxNy41IEM0NTUsMTMuMzU3ODY0NCA0NTguODE5NTcxLDEwIDQ2My41MzEyNSwxMCBMNDY4LjA5ODMzLDEwIEw0NjguMDk4MzMsMTAiIGlkPSJzd2FwX2ljb25fbWluaW5nIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==";
var navBorderLong = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTIycHgiIGhlaWdodD0iMTdweCIgdmlld0JveD0iMCAwIDEyMiAxNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT5zd2FwX2ljb25fbGlxdWlkaXR5PC90aXRsZT4KICAgIDxkZWZzPgogICAgICAgIDxsaW5lYXJHcmFkaWVudCB4MT0iNTAlIiB5MT0iMCUiIHgyPSI1MCUiIHkyPSIxMDAlIiBpZD0ibGluZWFyR3JhZGllbnQtMSI+CiAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiMwQUM1RjciIG9mZnNldD0iMCUiPjwvc3RvcD4KICAgICAgICAgICAgPHN0b3Agc3RvcC1jb2xvcj0iIzUwRDBCNyIgb2Zmc2V0PSIxMDAlIj48L3N0b3A+CiAgICAgICAgPC9saW5lYXJHcmFkaWVudD4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSLpobXpnaItMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Iua1geWKqOaApy3liKDpmaTvvIjljZXmnaHmlbDmja4v5aSa5p2h5pWw5o2u77yJIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODE0LjAwMDAwMCwgLTQ4LjAwMDAwMCkiIHN0cm9rZT0idXJsKCNsaW5lYXJHcmFkaWVudC0xKSI+CiAgICAgICAgICAgIDxnIGlkPSLnvJbnu4QtMTEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4Ny4wMDAwMDAsIDAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0i57yW57uELTEzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNTAuMDAwMDAwLCA0MS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMzg3LjkxNjgxNyw4IEwzOTAuNSw4IEMzOTQuNjQyMTM2LDggMzk4LDExLjM1Nzg2NDQgMzk4LDE1LjUgQzM5OCwxOS42NDIxMzU2IDM5NC42NDIxMzYsMjMgMzkwLjUsMjMgTDI4NS41LDIzIEMyODEuMzU3ODY0LDIzIDI3OCwxOS42NDIxMzU2IDI3OCwxNS41IEMyNzgsMTEuMzU3ODY0NCAyODEuMzU3ODY0LDggMjg1LjUsOCBMMjg5LjUxNTAxNiw4IiBpZD0ic3dhcF9pY29uX2xpcXVpZGl0eSI+PC9wYXRoPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=";
var StyledMenuItem = styled.a.withConfig({
    componentId: "sc-c8d13359-1"
})(_templateObject1(), function(param) {
    var theme = param.theme, $isActive = param.$isActive;
    return $isActive ? "#51D1B8" : theme.isDark ? "#F5FBF2" : "#002237";
}, function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "600" : "400";
}, function(param) {
    var $isDisabled = param.$isDisabled;
    return $isDisabled ? 0.5 : 1;
}, function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "linear-gradient(270deg, #50D0B7 0%, #00C3FF 100%)" : "none";
}, function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "transparent" : "initial";
}, function(param) {
    var $isActive = param.$isActive, $href = param.$href;
    return $isActive && '\n    &:after {\n      position: absolute;\n      left: 50%;\n      top: 22px;\n      content: "";\n      transform: translateX(-50%);\n      background-image: url('.concat($href === "/liquidity" ? navBorderLong : navBorder, ");\n      background-size: 100% 100%;\n      background-position: center;\n      background-repeat: no-repeat;\n      width: ").concat($href === "/liquidity" ? "110px" : "80px", ";\n      height: 17px;\n      z-index: 1;\n    }\n  ");
}, function(param) {
    var $statusColor = param.$statusColor, theme = param.theme;
    return $statusColor && '\n    &:after {\n      content: "";\n      border-radius: 100%;\n      background: '.concat(theme.colors[$statusColor], ";\n      height: 8px;\n      width: 8px;\n      margin-left: 12px;\n    }\n  ");
}, function(param) {
    var $variant = param.$variant;
    return $variant === "default" ? "\n    margin: 16px;\n    height: 48px;\n  " : "\n    margin: 4px 4px 0px 4px;\n    height: 42px;\n  ";
}, function(param) {
    var theme = param.theme;
    return theme.colors.primary;
}, function(param) {
    var theme = param.theme;
    return theme.colors.tertiary;
}, function(param) {
    var $variant = param.$variant;
    return $variant === "default" && "border-radius: 16px;";
});
export default StyledMenuItem;
