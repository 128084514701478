import { createAsyncThunk, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import fromPairs from 'lodash/fromPairs'
import { PoolsState, SerializedPool } from 'state/types'
import { getPoolApr } from 'utils/apr'
// import { multicallv2 } from 'utils/multicall'
import { getBalanceNumber } from 'utils/formatBalance'
import { rpgRpcProvider } from 'utils/providers'
import { SerializedPoolConfig } from 'config/constants/types'
// import { getPoolsPriceHelperLpFiles } from 'config/constants/priceHelperLps/index'
// import fetchFarms from '../farmsV1/fetchFarms'
// import getFarmsPrices from '../farms/getFarmsPrices'
import { getPoolConfig } from 'config/constants/pools'
import {
  fetchPoolsBlockLimits,
  fetchPoolsStakingLimits,
  fetchPoolsTotalStaking,
  fetchPoolsTokenPrices,
} from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
} from './fetchPoolsUser'
import { getTokenPricesFromTopToken } from './helpers'
import { resetUserState } from '../global/actions'

export const initialPoolVaultState = Object.freeze({
  totalShares: null,
  totalLockedAmount: null,
  pricePerFullShare: null,
  totalCakeInVault: null,
  fees: {
    performanceFee: null,
    withdrawalFee: null,
    withdrawalFeePeriod: null,
  },
  userData: {
    isLoading: true,
    userShares: null,
    cakeAtLastUserAction: null,
    lastDepositedTime: null,
    lastUserActionTime: null,
    credit: null,
    locked: null,
    lockStartTime: null,
    lockEndTime: null,
    userBoostedShare: null,
    lockedAmount: null,
    currentOverdueFee: null,
    currentPerformanceFee: null,
  },
  creditStartBlock: null,
})

export const initialIfoState = Object.freeze({
  credit: null,
  ceiling: null,
})

const initialState: PoolsState = {
  data: [],
  userDataLoaded: false,
  cakeVault: initialPoolVaultState,
  cakeFlexibleSideVault: initialPoolVaultState,
}

// Async thunks
export const fetchInitialPoolsData = createAsyncThunk<SerializedPoolConfig[], { chainId: number }>(
  'pool/fetchInitialPoolsData',
  async ({ chainId }) => {
    const poolDataList = await getPoolConfig()
    console.log('pool------------------fetchInitialPoolsData111')
    return poolDataList
  },
)

export const fetchPoolsPublicDataAsync =
  (currentBlockNumber: number, chainId: number, poolsConfig: SerializedPoolConfig[]) => async (dispatch, getState) => {
    console.log('pool-----------------fetchPoolsPublicDataAsync222')
    try {
      const [blockLimits, totalStakings, currentBlock, poolsTokenPrices] = await Promise.all([
        fetchPoolsBlockLimits(poolsConfig),
        fetchPoolsTotalStaking(poolsConfig),
        currentBlockNumber ? Promise.resolve(currentBlockNumber) : rpgRpcProvider.getBlockNumber(),
        fetchPoolsTokenPrices(),
      ])

      // console.log('poolsTokenPrices', poolsTokenPrices)

      const blockLimitsSousIdMap: any = fromPairs(blockLimits.map((entry) => [entry.sousId, entry]))
      const totalStakingsSousIdMap: any = fromPairs(totalStakings.map((entry) => [entry.sousId, entry]))

      // console.log('totalStakingsSousIdMap', totalStakingsSousIdMap, blockLimitsSousIdMap)
      const prices = getTokenPricesFromTopToken([...poolsTokenPrices])

      const liveData = poolsConfig.map((pool) => {
        const blockLimit = blockLimitsSousIdMap[pool.sousId]
        const totalStaking = totalStakingsSousIdMap[pool.sousId]
        // console.log('totalStaking', pool, totalStaking, blockLimit.endBlock)
        const isPoolEndBlockExceeded =
          currentBlock > 0 && (blockLimit ? currentBlock > Number(blockLimit.endBlock) : false)
        const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded

        const stakingTokenAddress = pool.stakingToken.address ? pool.stakingToken.address.toLowerCase() : null
        const stakingTokenPrice = stakingTokenAddress && prices[stakingTokenAddress] ? prices[stakingTokenAddress] : 0

        const earningTokenAddress = pool.earningToken.address ? pool.earningToken.address.toLowerCase() : null
        const earningTokenPrice = earningTokenAddress && prices[earningTokenAddress] ? prices[earningTokenAddress] : 0

        const earnIconObj = poolsTokenPrices?.filter(
          (v) => v.address.toLowerCase() === pool.earningToken.address.toLowerCase(),
        )[0]
        const stakeIconObj = poolsTokenPrices?.filter(
          (v) => v.address.toLowerCase() === pool.stakingToken.address.toLowerCase(),
        )[0]
        // console.log('earnIcon/stakeIcon', earnIconObj.icon, stakeIconObj.icon)
        // console.log('1111111', pool, blockLimit.endBlock, currentBlock, currentBlockNumber)
        const apr = !isPoolEndBlockExceeded
          ? getPoolApr(
              stakingTokenPrice,
              earningTokenPrice,
              getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimals),
              parseFloat(pool.tokenPerBlock),
            )
          : 0

        return {
          ...blockLimit,
          ...totalStaking,
          stakingTokenPrice,
          earningTokenPrice,
          apr,
          earnIcon: earnIconObj.icon,
          stakeIcon: stakeIconObj.icon,
          isFinished: isPoolFinished,
        }
      })
      dispatch(setPoolsPublicData(liveData))
    } catch (error) {
      console.error('[Pools Action] error when getting public data', error)
    }
  }

export const fetchPoolsStakingLimitsAsync = (poolsConfig) => async (dispatch, getState) => {
  // const poolsWithStakingLimit = getState()
  //   .pools.data.filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
  //   .map((pool) => pool.sousId)

  try {
    // const stakingLimits = await fetchPoolsStakingLimits(poolsWithStakingLimit)
    const stakingLimits = await fetchPoolsStakingLimits(poolsConfig)
    // console.log('stakingLimits=', stakingLimits)

    dispatch(setPoolsPublicData(stakingLimits))
  } catch (error) {
    console.error('[Pools Action] error when getting staking limits', error)
  }
}

export const fetchPoolsUserDataAsync = createAsyncThunk<
  { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; pendingReward: any }[],
  string
>('pool/fetchPoolsUserData', async (account, { rejectWithValue }) => {
  console.log('pool-----------------pool/fetchPoolsUserData333')
  try {
    const poolsConfig = localStorage.getItem('poolsList') ? JSON.parse(localStorage.getItem('poolsList')) : []
    const [allowances, stakingTokenBalances, stakedBalances, pendingRewards] = await Promise.all([
      fetchPoolsAllowance(account, poolsConfig),
      fetchUserBalances(account, poolsConfig),
      fetchUserStakeBalances(account, poolsConfig),
      fetchUserPendingRewards(account, poolsConfig),
    ])

    const userData = poolsConfig.map((pool) => ({
      sousId: pool.sousId,
      allowance: allowances[pool.sousId],
      stakingTokenBalance: stakingTokenBalances[pool.sousId],
      stakedBalance: stakedBalances[pool.sousId],
      pendingReward: pendingRewards[pool.sousId],
    }))
    return userData
  } catch (e) {
    return rejectWithValue(e)
  }
})

export const updateUserAllowance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; poolsConfig: any }
>('pool/updateUserAllowance', async ({ sousId, account, poolsConfig }) => {
  const allowances = await fetchPoolsAllowance(account, poolsConfig)
  return { sousId, field: 'allowance', value: allowances[sousId] }
})

export const updateUserBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; poolsConfig: any }
>('pool/updateUserBalance', async ({ sousId, account, poolsConfig }) => {
  const tokenBalances = await fetchUserBalances(account, poolsConfig)
  return { sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }
})

export const updateUserStakedBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; poolsConfig: any }
>('pool/updateUserStakedBalance', async ({ sousId, account, poolsConfig }) => {
  const stakedBalances = await fetchUserStakeBalances(account, poolsConfig)
  return { sousId, field: 'stakedBalance', value: stakedBalances[sousId] }
})

export const updateUserPendingReward = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; poolsConfig: any }
>('pool/updateUserPendingReward', async ({ sousId, account, poolsConfig }) => {
  const pendingRewards = await fetchUserPendingRewards(account, poolsConfig)
  return { sousId, field: 'pendingReward', value: pendingRewards[sousId] }
})

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolPublicData: (state, action) => {
      const { sousId } = action.payload
      const poolIndex = state.data.findIndex((pool) => pool.sousId === sousId)
      state.data[poolIndex] = {
        ...state.data[poolIndex],
        ...action.payload.data,
      }
    },
    setPoolUserData: (state, action) => {
      const { sousId } = action.payload
      state.data = state.data.map((pool) => {
        if (pool.sousId === sousId) {
          return { ...pool, userDataLoaded: true, userData: action.payload.data }
        }
        return pool
      })
    },
    setPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      const livePoolsSousIdMap = fromPairs(livePoolsData.map((entry) => [entry.sousId, entry]))
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsSousIdMap[pool.sousId]
        return { ...pool, ...livePoolData }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUserState, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state.data = state.data.map(({ userData, ...pool }) => {
        return { ...pool }
      })
      state.userDataLoaded = false
    })
    builder.addCase(fetchInitialPoolsData.fulfilled, (state, action) => {
      const poolData = action.payload
      state.data = poolData
    })
    builder.addCase(
      fetchPoolsUserDataAsync.fulfilled,
      (
        state,
        action: PayloadAction<
          { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; pendingReward: any }[]
        >,
      ) => {
        const userData = action.payload
        const userDataSousIdMap = fromPairs(userData.map((entry) => [entry.sousId, entry]))
        state.data = state.data.map((pool) => ({
          ...pool,
          userDataLoaded: true,
          userData: userDataSousIdMap[pool.sousId],
        }))
        state.userDataLoaded = true
      },
    )
    builder.addCase(fetchPoolsUserDataAsync.rejected, (state, action) => {
      console.error('[Pools Action] Error fetching pool user data', action.payload)
    })
    builder.addMatcher(
      isAnyOf(
        updateUserAllowance.fulfilled,
        updateUserBalance.fulfilled,
        updateUserStakedBalance.fulfilled,
        updateUserPendingReward.fulfilled,
      ),
      (state, action: PayloadAction<{ sousId: number; field: string; value: any }>) => {
        const { field, value, sousId } = action.payload
        const index = state.data.findIndex((p) => p.sousId === sousId)

        if (index >= 0) {
          state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
        }
      },
    )
  },
})

// Actions
export const { setPoolsPublicData, setPoolPublicData, setPoolUserData } = PoolsSlice.actions

export default PoolsSlice.reducer
