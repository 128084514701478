import memoize from 'lodash/memoize'
import { ContextApi } from '@pancakeswap/localization'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'PonytaSwap',
  description:
    'The most popular AMM on RPG by user count! Earn RPG through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PonytaSwap), and more, on a platform you can trust.',
  image: 'https://ponytaswap.finance/images/hero.png',
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { title: t('Exchange') },
      '/swap': { basePath: true, title: t('Exchange') },
      '/limit-orders': { basePath: true, title: t('Limit Orders') },
      '/add': { basePath: true, title: t('Add Liquidity') },
      '/remove': { basePath: true, title: t('Remove Liquidity') },
      '/liquidity': { title: t('Liquidity') },
      '/find': { title: t('Import Pool') },
      '/farms': { title: t('Farms') },
      '/pools': { title: t('Pools') },
      '/nfts': { title: t('nfts') },
      '/voting': { basePath: true, title: t('Voting') },
      '/voting/proposal': { title: t('Proposal Detail') },
      '/voting/proposal/create': { title: t('Make a Proposal') },
      '/info': { title: t('Info'), description: 'View statistics for Ponytaswap exchanges.' },
      '/ido': { title: t('IDO') },
      '/ido/auctionDetail': { title: t('IDO Auction Detail') },
      '/ido/createAuction': { title: t('Create Auction') },
    },
    defaultTitleSuffix: t('PonytaSwap'),
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title} | ${t(pathList.defaultTitleSuffix)}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
