import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Kastelov';
    src: url(../../images/fonts/Kastelov.ttf);
    src: url(../../images/fonts/Kastelov.otf);
  }
  * {
    font-family: 'Kastelov', 'Kanit', sans-serif;
  }
  body,
  html {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
    background-color: ${({ theme }) => theme.colors.backgroundAlt3};
    img {
      height: auto;
      max-width: 100%;
    }

    .checkbox {
      &:checked {
        background-color: #31d0aa;
        box-shadow: none;
      }
      &:hover, &:focus {
        &:not {
          box-shadow: none;
        }
        &:not(:disabled):not(:checked) {
          // box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
        }
      }
    }
    .group-btn-active {
      background: linear-gradient(270deg, #50D0B7 0%, #00C3FF 100%);
    }
    .group-btn {
      color: ${({ theme }) => theme.colors.text};
      background: ${({ theme }) => theme.colors.input};
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      font-weight: 800;
    }
    .group-btn2 {
      color: ${({ theme }) => theme.colors.text};
      background: transparent;
      border: 1px solid rgba(229,229,229,0.5);
      border-radius: 5px;
      font-weight: 800;
    }
    .group-setting {
      font-size: 16px;
      padding: 20px 24px;
      height: auto;
    }
    .group-setting-mobile {
      font-size: 14px;
      padding: 20px 12px;
      height: auto;
    }
    .input-setting {
      font-size: 16px;
      border-radius: 10px;
      height: 56px;
      border: 0;
    }
    .input-setting-mobile {
      font-size: 14px;
      border-radius: 10px;
      height: 46px;
      border: 0;
    }

    .input-tolerance {
      color: ${({ theme }) => theme.colors.inputTxt};
      background: ${({ theme }) => theme.colors.inputBackground};
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      border-radius: 16px;
      border: 0;
      padding: 29px 19px;
    }
    .global-btn {
      color: ${({ theme }) => theme.colors.btnColor};
      background: linear-gradient(270deg, #D0ED33 0%, #00C3FF 100%);
      height: 48px;
      // background: ${({ theme }) => theme.colors.background};
      // box-shadow: ${({ theme }) => theme.colors.borderBoxShadow};
      border-radius: 12px;
      .wallet-style {
        display: none;
      }
    }
    .gradient-txt {
      background-image: linear-gradient(270deg, #50D0B7 0%, #00C3FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .list-common {
      color: ${({ theme }) => theme.colors.btnColor};
      background: linear-gradient(270deg, #50D0B7 0%, #00C3FF 100%);
      border: none;
    }
    .currencyButton {
      background: ${({ theme }) => theme.colors.selectCurrency};
      border-radius: 10px;
      padding: 9px 20px;
      margin-left: 10px;
    }
    .input-box {
      background: ${({ theme }) => theme.colors.input};
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      border-radius: 12px;
      padding: 10px 12px;
    }
    .input-box-no {
      color: ${({ theme }) => (theme.isDark ? '#fff !important' : '#333 !important')};
      border: ${({ theme }) => (theme.isDark ? '0' : '1px solid #dbdce0')};
      background: ${({ theme }) => theme.colors.input};
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      border-radius: 12px;
      height: 48px;
    }
    .input-small-box {
      background: ${({ theme }) => theme.colors.input};
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      border-radius: 8px;
      padding: 20px 0;
      width: 104px;
      ${({ theme }) => theme.mediaQueries.sm} {
        width: 135px;
      }
    }
    .icon-bg-shadow {
      background: ${({ theme }) => theme.colors.btnBackground};
      box-shadow: ${({ theme }) => theme.colors.btnBoxShadow};
      border-radius: ${({ theme }) => theme.radii.card};
    }
    .coin-btn {
      color: ${({ theme }) => theme.colors.text} !important;
      background: transparent;
      border-radius: 12px;
      border: 1px solid #727272;
      margin: 14px 0 20px;
    }
    .coin-btn2 {
      color: ${({ theme }) => theme.colors.btnColor};
      background: transparent;
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      border-radius: 30px;
      border: 1px solid rgba(195,201,208,0.25);
    }
    .max-btn {
      color: ${({ theme }) => theme.colors.btnColor};
      background: ${({ theme }) => theme.colors.background};
      box-shadow: ${({ theme }) => theme.colors.maxBtnBoxShadow};
      border-radius: 12px;
    }
    .harvest-btn {
      background-color: ${({ theme }) => theme.colors.background};
      box-shadow: ${({ theme }) => theme.colors.harvestBtnBoxShadow};
      border-radius: 14px;
      height: 28px;
      padding: 7px 16px;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.textSubtle};
      &:disabled {
        color: ${({ theme }) => theme.colors.textSubtle};
        background-color: ${({ theme }) => theme.colors.background};
        box-shadow: ${({ theme }) => theme.colors.harvestBtnBoxShadow};
      }
    }
    .mining-btn {
      background: ${({ theme }) => theme.colors.nftBackground};
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      border-radius: 16px;
      font-size: 12px;
      // height: 28px;
      color: ${({ theme }) => theme.colors.btnColor2} !important;
      // &:disabled {
      //   color: ${({ theme }) => theme.colors.textSubtle};
      //   background-color: ${({ theme }) => theme.colors.backgroundDisabled};
      //   box-shadow: ${({ theme }) => theme.colors.btnBoxShadow}
      // }
      .wallet-style {
        display: none;
      }
    }
    .mining-active-btn {
      background: linear-gradient(270deg, #D0ED33 0%, #00C3FF 100%);
      border-radius: 16px;
      font-size: 12px;
      // height: 28px;
      color: ${({ theme }) => theme.colors.nftColor};
      // &:disabled {
      //   color: ${({ theme }) => theme.colors.textDisabled};
      //   background-color: ${({ theme }) => theme.colors.backgroundDisabled};
      //   box-shadow: ${({ theme }) => theme.colors.btnBoxShadow}
      // }
      .wallet-style {
        display: none;
      }
    }
    .farm-unfold {
      background: ${({ theme }) => theme.colors.background};
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      border-radius: 12px;
      margin-bottom: 16px;
      tr {
        background: transparent;
        box-shadow: none;
        margin-bottom: 0;
        ${({ theme }) => theme.mediaQueries.lg} {
          margin-bottom: 16px;
        }
        td {
          > div {
            padding: 0;
            ${({ theme }) => theme.mediaQueries.lg} {
              padding: 24px 32px 0 0;
            }
          }
        }
      }
    }
    .text-btn {
      border-radius: 12px;
      border: 1px solid ${({ theme }) => theme.colors.textSubtle};
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 400;
      padding: 8px 29px;
      height: auto;
    }
    .normal-action-btn {
      width: 101px;
      background: ${({ theme }) => theme.colors.invertedContrast};
      box-shadow: ${({ theme }) => theme.colors.btnBoxShadow};
      border-radius: 12px;
      color: ${({ theme }) => theme.colors.textDisabled};
    }
    .primary-action-btn {
      background: ${({ theme }) => theme.colors.nftBackground};
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      color: ${({ theme }) => theme.colors.btnColor2};
      height: 48px;
      border-radius: 12px;
      opacity: 1;
    }
    .border-style {
      // background: ${({ theme }) => theme.colors.backgroundAlt};
      // box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset} !important;
      // border-radius: ${({ theme }) => theme.radii.card};
    }
    .cursor {
      cursor: pointer;
    }
    .limit-table {
      background-color: ${({ theme }) => theme.colors.backgroundAlt3};
      border-radius: ${({ theme }) => theme.radii.card};
    }
    .token-liquidity {
      background: ${({ theme }) => theme.colors.input};
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      border-radius: 12px;
    }
    .more-liquidity {
      box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
    }
    .chart-line {
      background: ${({ theme }) => theme.colors.backgroundAlt3};
      // box-shadow: ${({ theme }) => theme.colors.borderBoxShadowInset};
      border-radius: ${({ theme }) => theme.radii.card};
    }
    .max-value {
      background: ${({ theme }) => theme.colors.background};
      box-shadow: ${({ theme }) => theme.colors.maxBtnBoxShadow};
      border-radius: ${({ theme }) => theme.radii.small};
    }
    .refresh-icon {
      width: auto;
      height: auto;
      padding: 0;
      background-color: transparent;
    }
    .text-link {
      color: ${({ theme }) => theme.colors.text};
      text-decoration: underline;
    }
    .ido-create {
      max-width: 536px;
      width: 100%;
      margin: 0 auto 30px;
    }
    .readonly-text {
      background: ${({ theme }) => theme.colors.backgroundAlt3};
      padding: 9px 10px;
      border-radius: ${({ theme }) => theme.radii.middle};
    }
    .input-custom {
      background: ${({ theme }) => theme.colors.backgroundAlt3};
      border-radius: ${({ theme }) => theme.radii.middle};
      color: ${({ theme }) => theme.colors.from};
      border: none;
      box-shadow: none;
      font-size: 12px;
    }
  }
`

export default GlobalStyle
