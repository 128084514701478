// import nftsConfig from 'config/constants/nfts'
import statkeNftABI from 'config/abi/stakeNft.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
// import { getAddress, getNftStakeAddress } from 'utils/addressHelpers'
import { rpgRpcProvider } from 'utils/providers'
import BigNumber from 'bignumber.js'
import uniq from 'lodash/uniq'
import fromPairs from 'lodash/fromPairs'

// export const fetchPoolsAllowance = async (account) => {
//   const calls = nftsConfig.map((nft) => ({
//     address: nft.stakingToken.address,
//     name: 'allowance',
//     params: [account, getAddress(nft.contractAddress)],
//   }))

//   const allowances = await multicall(erc20ABI, calls)
//   console.log('nft allowance', allowances)
//   return fromPairs(nftsConfig.map((pool, index) => [pool.sousId, new BigNumber(allowances[index]).toJSON()]))
// }

export const fetchUserBalances = async (account, nftsConfig) => {
  const tokens = uniq(nftsConfig.map((nft) => nft.stakingToken.address))
  const calls = tokens.map((token) => ({
    address: token as string,
    name: 'balanceOf',
    params: [account],
  }))
  const [tokenBalancesRaw] = await Promise.all([multicall(erc20ABI, calls), rpgRpcProvider.getBalance(account)])

  const tokenBalances = fromPairs(tokens.map((token, index) => [token, tokenBalancesRaw[index]]))

  const poolTokenBalances = fromPairs(
    nftsConfig
      .map((nft) => {
        if (!tokenBalances[nft.stakingToken.address]) return null
        return [nft.sousId, new BigNumber(tokenBalances[nft.stakingToken.address]).toJSON()]
      })
      .filter(Boolean),
  )
  console.log('nft balanceOf', tokenBalances, poolTokenBalances)

  return { ...poolTokenBalances }
}

export const fetchUserStakeBalances = async (account, nftsConfig) => {
  const calls = nftsConfig.map((p) => ({
    address: p.contractAddress,
    name: 'userInfo', // getUserInfo
    params: [p.sousId, account],
  }))
  const userInfo = await multicall(statkeNftABI, calls)

  /**
   * return [0] uint256 赚到的代币（单位：wei）
   * return [1] uint256 用户质押的NFT数量（单位：个）
   * return [2] uint256 用户质押的权重（单位：个），放大100倍（展示除100）
   * return [3] uint256 用户质押的一天的收益：（单位：wei）
   */

  // console.log('nft fetchUserStakeBalances 用户质押的NFT数量', calls, account)
  return fromPairs(nftsConfig.map((nft, index) => [nft.sousId, new BigNumber(userInfo[index][1]?.toString()).toJSON()]))
}

export const fetchUserPendingRewards = async (account, nftsConfig) => {
  const calls = nftsConfig.map((p) => ({
    address: p.contractAddress,
    name: 'pendingCake',
    params: [p.sousId, account],
  }))
  const res = await multicall(statkeNftABI, calls)
  // console.log('nft pendingCake', new BigNumber(res[0]).toJSON())
  return fromPairs(nftsConfig.map((nft, index) => [nft.sousId, new BigNumber(res[index]).toJSON()]))
}
