export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const GRAPH_API_PROFILE = process.env.NEXT_PUBLIC_GRAPH_API_PROFILE
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const STAT_ANALYSIS = `${process.env.NEXT_PUBLIC_URL_SERVICE}/info/overview`
export const TOKEN_TOP = `${process.env.NEXT_PUBLIC_URL_SERVICE}/info/tokentop`
export const WHITE_LIST_QUERY = `${process.env.NEXT_PUBLIC_URL_SERVICE}/info/assetsearch`

export const POOL_TOP = `${process.env.NEXT_PUBLIC_URL_SERVICE}/info/lptop`

// https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2
export const INFO_CLIENT = ''
export const BLOCKS_CLIENT = ''
export const GRAPH_HEALTH = ''
// export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/graphql'

/**
 * v2 limit order api
 */
export const LIMIT_ORDER_BUILD = `${process.env.NEXT_PUBLIC_URL_SERVICE}/limit-order/build`
export const LIMIT_ORDER_PUSH = `${process.env.NEXT_PUBLIC_URL_SERVICE}/limit-order/push`
export const LIMIT_ORDER_QUERY = `${process.env.NEXT_PUBLIC_URL_SERVICE}/limit-order/address/`
export const LIMIT_ORDER_CANCEL = `${process.env.NEXT_PUBLIC_URL_SERVICE}/limit-order/cancel`

/**
 *  Upload WhiteList
 */
export const UPLOAD_WHITELIST = `${process.env.NEXT_PUBLIC_URL_SERVICE}/common/whitelist`
/**
 * Create Auction
 */
export const CREATE_AUCTION = `${process.env.NEXT_PUBLIC_URL_SERVICE}/market/build`
/**
 *  Scan Data
 */
export const SCAN_DATA = `${process.env.NEXT_PUBLIC_URL_SERVICE}/market/job/scan`
/**
 * Get Auction List
 */
export const GET_AUCTION_LIST = `${process.env.NEXT_PUBLIC_URL_SERVICE}/market/list`
/**
 * Get Auction Detail
 */
export const GET_AUCTION_DETAIL = `${process.env.NEXT_PUBLIC_URL_SERVICE}/market/detail`
/**
 * UPDATE_AUCTION
 */
export const UPDATE_AUCTION_DETAIL = `${process.env.NEXT_PUBLIC_URL_SERVICE}/market/update`
/**
 * Get Purchase Record
 */
export const GET_PURCHASE_RECORD = `${process.env.NEXT_PUBLIC_URL_SERVICE}/market/purchase`

/**
 * Get Vote List
 */
export const GET_VOTING_LIST = `${process.env.NEXT_PUBLIC_URL_SERVICE}/vote/proposals`
/**
 * Create Vote Proposal
 */
export const CREATE_VOTE_PROPOSAL = `${process.env.NEXT_PUBLIC_URL_SERVICE}/vote/proposal/create`
/**
 * Get Vote Proposal Detail
 */
export const GET_PROPOSAL_DETAIL = `${process.env.NEXT_PUBLIC_URL_SERVICE}/vote/proposal/detail`
/**
 * Get Vote Total
 */
export const GET_PROPOSAL_TOTAL = `${process.env.NEXT_PUBLIC_URL_SERVICE}/vote/proposal/votes`

/**
 * Get NFT Stake List
 */
export const GET_NFT_STAKE_LIST = `${process.env.NEXT_PUBLIC_URL_SERVICE}/nft/assets`
/**
 * Update NFT balance
 */
export const UPDATE_NFT_BALANCE = `${process.env.NEXT_PUBLIC_URL_SERVICE}/nft/asset/refresh`

/**
 * Admin Token List
 */
export const GET_TOKEN_LIST = `${process.env.NEXT_PUBLIC_URL_SERVICE}/admin/token`

/**
 * Admin Farm List
 */
export const GET_FARM_LIST = `${process.env.NEXT_PUBLIC_URL_SERVICE}/stake/farm`

/**
 * Admin Pool List
 */
export const GET_POOL_LIST = `${process.env.NEXT_PUBLIC_URL_SERVICE}/stake/token`

/**
 * Admin Nft List
 */
export const GET_NFT_LIST = `${process.env.NEXT_PUBLIC_URL_SERVICE}/stake/nft`

/**
 * Get Token Detail Data
 */
export const GET_TOKEN_DETAIL = `${process.env.NEXT_PUBLIC_URL_SERVICE}/graph/token`

/**
 * Get Pool Pair Detail Data
 */
export const GET_PAIR_DETAIL = `${process.env.NEXT_PUBLIC_URL_SERVICE}/graph/pair`
