// import { ChainId, Token } from '@pancakeswap/sdk'
import { USDT_ROBIN, PVT_MAINNET, MIX_MAINNET, WRPG_MAINNET } from './common'

export const robMainnetTokens = {
  // weth: WETH9[ChainId.ROBIN],
  pvt: PVT_MAINNET,
  mix: MIX_MAINNET,
  wrpg: WRPG_MAINNET,
  usdt: USDT_ROBIN,
}
