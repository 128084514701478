import { ChainId } from '@pancakeswap/sdk'
import FarmsRobinPriceHelper from './farms/9527'
import PoolsRobinPriceHelper from './pools/9527'

export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.ROBIN_TESTNET:
      return FarmsRobinPriceHelper
    default:
      return []
  }
}

export const getPoolsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.ROBIN_TESTNET:
      return PoolsRobinPriceHelper
    default:
      return []
  }
}
