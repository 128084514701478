import { ChainId } from '@pancakeswap/sdk'
import qs from 'qs'
import { SerializedFarmConfig } from '../types'
import { GET_FARM_LIST } from '../endpoints'

let logged = false

export const getFarmConfig = async (chainId: ChainId) => {
  const params = qs.stringify({
    page: 1,
    page_size: 100,
    isView: 'true',
  })
  try {
    const result = await (await fetch(`${GET_FARM_LIST}?${params}`)).json()
    const farmsList: SerializedFarmConfig[] = []
    if (result?.status === 200) {
      const data = result?.data.content ? result?.data.content : []
      // console.log('农场LP数据', result?.data?.content)
      if (data.length > 0) {
        data.forEach((item: any) => {
          farmsList.push({
            masterChefV1: item.CcStakeFarm.address,
            lpPrice: item.lpPrice,
            lpAddress: item.stakeLpToken,
            startBlock: item.CcStakeFarm.startBlock,
            pid: item.pid,
            lpSymbol: item.lpSymbol,
            quoteToken: item.quoteToken,
            token: item.token,
            quoteIcon: '',
            tokenIcon: '',
            bonusTokenName: item.CcStakeFarm.bonusTokenName,
            bonusToken: item.CcStakeFarm.bonusToken,
          })
        })
        localStorage.setItem('farmsList', JSON.stringify(farmsList))
        localStorage.setItem('farmData', JSON.stringify(farmsList[0]))
        return farmsList
      }
      localStorage.removeItem('farmsList')
      localStorage.removeItem('farmData')
      return []
    }
    return []

    // return (await import(`/${chainId}.ts`)).default as SerializedFarmConfig[]
  } catch (error) {
    if (!logged) {
      console.error('Cannot get farm config', error, chainId)
      logged = true
    }
    return []
  }
}
