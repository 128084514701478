export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
  PRICE = 'PRICE',
}

export enum Rate {
  MUL = 'MUL',
  DIV = 'DIV',
}

export interface Order {
  salt: string
  makerAsset: string
  takerAsset: string
  maker: string
  receiver: string
  allowedSender: string
  makingAmount: string
  takingAmount: string
  makerAssetData: string
  takerAssetData: string
  getMakerAmount: string
  getTakerAmount: string
  predicate: string
  permit: string
  interaction: string

  // id?: string
  // minReturn?: string
  // maxReturn?: string
  // adjustedMinReturn?: string
  // status?: string
  // createdTxHash?: string
  // executedTxHash?: string | null
  // cancelledTxHash?: string | null
  // blockNumber?: string
  // createdAt?: string
  // updatedAt?: string
  // updatedAtBlock?: string
  // updatedAtBlockHash?: string
  // data?: string
  // handler?: string | null
  // isExpired?: boolean
}

export interface QueryOrder {
  id: number
  createdAt: string
  expired: string
  account: string
  status: number // 1:有效 2:有效上链 3:无效订单
  reason: number // 0:正常 1:过期 2:取消 3:已执行 其他
  remaining: string
  totle: string
  signature: string
  orderHash: string
  orderData: Order
  createdTxHash?: string
  executedTxHash?: string | null
  cancelledTxHash?: string | null
}

export interface OrderState {
  readonly independentField: Field
  readonly basisField: Field
  readonly typedValue: string
  readonly inputValue?: string
  readonly outputValue?: string
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined
  }

  readonly rateType: Rate

  readonly isFromTo?: boolean
  readonly isInputVal?: boolean
}
