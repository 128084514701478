import styled from 'styled-components'
import { Button, ButtonProps, WalletFilledIcon } from '@pancakeswap/uikit'
import { useWallet } from 'hooks/useWallet'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import Trans from './Trans'

const WalletWrapper = styled.div`
  // background: ${({ theme }) => theme.colors.btnBackground};
  // box-shadow: ${({ theme }) => theme.colors.btnBoxShadow};
  // border-radius: 24px;
  margin-left: 12px;
  // padding: 7px;
`

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { onPresentConnectModal } = useWallet()
  const { hideIcon } = props

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      console.log('1')
      handleActive()
    } else {
      onPresentConnectModal()
    }
  }

  return (
    <Button variant="text" onClick={handleClick} {...props} color="primary">
      {children || <Trans>Connect Wallet</Trans>}
      {!hideIcon && (
        <WalletWrapper>
          <WalletFilledIcon width="33" />
        </WalletWrapper>
      )}
    </Button>
  )
}

export default ConnectWalletButton
