// import poolsConfig from 'config/constants/pools'
// import statkeTokenABI from 'config/abi/sousChef.json'
import statkeTokenABI from 'config/abi/stakeToken.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getFarmBoxAddress } from 'utils/addressHelpers'
import { rpgRpcProvider } from 'utils/providers'
import BigNumber from 'bignumber.js'
import uniq from 'lodash/uniq'
import fromPairs from 'lodash/fromPairs'

// Pool 0, Cake / Cake is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
// const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'BNB')
// const bnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'BNB')
// const nonMasterPools = poolsConfig.filter((pool) => pool.sousId !== 0)

export const fetchPoolsAllowance = async (account, poolsConfig) => {
  const calls = poolsConfig.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'allowance',
    params: [account, getFarmBoxAddress()],
  }))

  const allowances = await multicall(erc20ABI, calls)

  return fromPairs(poolsConfig.map((pool, index) => [pool.sousId, new BigNumber(allowances[index]).toJSON()]))
}

export const fetchUserBalances = async (account, poolsConfig) => {
  // pools
  const tokens = uniq(poolsConfig.map((pool) => pool.stakingToken.address))
  const calls = tokens.map((token) => ({
    address: token as string,
    name: 'balanceOf',
    params: [account],
  }))
  const [tokenBalancesRaw] = await Promise.all([multicall(erc20ABI, calls), rpgRpcProvider.getBalance(account)])

  const tokenBalances = fromPairs(tokens.map((token, index) => [token, tokenBalancesRaw[index]]))

  const poolTokenBalances = fromPairs(
    poolsConfig
      .map((pool) => {
        if (!tokenBalances[pool.stakingToken.address]) return null
        return [pool.sousId, new BigNumber(tokenBalances[pool.stakingToken.address]).toJSON()]
      })
      .filter(Boolean),
  )
  // console.log('balanceOf', tokenBalances, poolTokenBalances)

  // BNB pools
  // const bnbBalanceJson = new BigNumber(bnbBalance.toString()).toJSON()
  // const bnbBalances = fromPairs(poolsConfig.map((pool) => [pool.sousId, bnbBalanceJson]))

  return { ...poolTokenBalances }
}

export const fetchUserStakeBalances = async (account, poolsConfig) => {
  const getInfoCalls = poolsConfig.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress,
      name: 'userInfo',
      params: [poolConfig.sousId % 100, account],
    }
  })
  const userInfo = await multicall(statkeTokenABI, getInfoCalls)
  // console.log('fetchUserStakeBalances', userInfo[0]?.toString())
  return fromPairs(
    poolsConfig.map((pool, index) => [pool.sousId, new BigNumber(userInfo[index][0]?.toString()).toJSON()]),
  )
}

export const fetchUserPendingRewards = async (account, poolsConfig) => {
  const calls = poolsConfig.map((p) => ({
    address: p.contractAddress,
    name: 'pendingReward',
    params: [p.sousId, account],
  }))
  const res = await multicall(statkeTokenABI, calls)
  // console.log('pendingReward', new BigNumber(res[0]).toJSON())
  return fromPairs(poolsConfig.map((pool, index) => [pool.sousId, new BigNumber(res[index]).toJSON()]))
}
