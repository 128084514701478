// import { robTestnetTokens } from '@pancakeswap/tokens'
// import addresses from 'config/constants/contracts'
import qs from 'qs'
import { SerializedPoolConfig } from './types'
import { GET_NFT_LIST } from './endpoints'

let logged = false

export const getNftConfig = async () => {
  const params = qs.stringify({
    page: 1,
    page_size: 100,
    isView: 'true',
  })
  try {
    const result = await (await fetch(`${GET_NFT_LIST}?${params}`)).json()
    const nftsList: SerializedPoolConfig[] = []
    if (result?.status === 200) {
      // console.log('NFT质押数据', result?.data?.content)
      const data = result?.data.content ? result?.data.content : []
      if (data.length) {
        data.forEach((item: any) => {
          nftsList.push({
            sousId: item.poolIndex,
            stakingToken: item.stakingToken,
            earningToken: item.earningToken,
            contractAddress: item.CcStakeNft.address,
            startBlock: item.startBlock,
            endBlock: item.endBlock,
          })
        })
        localStorage.setItem('nftsList', JSON.stringify(nftsList))
        return nftsList
      }
      localStorage.removeItem('nftsList')
      return []
    }
    return []
  } catch (error) {
    if (!logged) {
      console.error('Cannot get nft config', error)
      logged = true
    }
    return []
  }
}

const liveNfts: SerializedPoolConfig[] = [
  // {
  //   sousId: 0,
  //   contractAddress: addresses.nftStake,
  //   earningToken: robTestnetTokens.cake,
  //   stakingToken: robTestnetTokens.nft1,
  // },
  // {
  //   sousId: 1000,
  //   contractAddress: addresses.nftStake,
  //   earningToken: robTestnetTokens.cake,
  //   stakingToken: robTestnetTokens.nft1,
  // },
  // {
  //   sousId: 1001,
  //   contractAddress: addresses.nftStake,
  //   earningToken: robTestnetTokens.matic,
  //   stakingToken: robTestnetTokens.nft2,
  // },
].map((p) => ({ ...p, stakingToken: p.stakingToken.serialize, earningToken: p.earningToken.serialize }))

export default liveNfts
