import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { TwitterIcon, TelegramIcon, WhitepaperIcon } from "../Svg";
export var footerLinks = [];
export var socials = [
    {
        label: "Whitepaper",
        icon: WhitepaperIcon,
        href: "https://ponytaswap.gitbook.io/ponytaswap/"
    },
    {
        label: "Twitter",
        icon: TwitterIcon,
        href: "https://twitter.com/ponytaswap"
    },
    {
        label: "Telegram",
        icon: TelegramIcon,
        href: "https://t.me/ponytaswap"
    }, 
];
export var langs = _to_consumable_array(Array(20)).map(function(_, i) {
    return {
        code: "en".concat(i),
        language: "English".concat(i),
        locale: "Locale".concat(i)
    };
});
