import { MultiCallV2 } from '@pancakeswap/multicall'
// import { getMasterChefV1Address } from 'utils/addressHelpers'
// import addresses from 'config/constants/contracts'
import { farmV2FetchFarms, fetchFarmsParams, fetchMasterChefV2Data as _fetchMasterChefV2Data } from './fetchFarms'

import { farmV1FetchFarms, fetchV1FarmsParams, fetchMasterChefV1Data as _fetchMasterChefV1Data } from './fetchFarmsV1'

export const masterChefAddresses = ''

const supportedChainId = [9527, 2025]

export function createFarmFetcher(multicall: MultiCallV2) {
  const fetchFarms = (params: Omit<fetchFarmsParams, 'masterChefAddresses' | 'multicall'>) => {
    return farmV2FetchFarms({ ...params, multicall, masterChefAddresses })
  }
  return {
    fetchFarms,
    fetchMasterChefV2Data: (isTestnet: boolean) =>
      _fetchMasterChefV2Data({ isTestnet, masterChefAddresses, multicall }),
    isChainSupported: (chainId: number) => supportedChainId.includes(chainId),
  }
}

export function createFarmV1Fetcher(multicall: MultiCallV2) {
  const fetchFarmsV1 = (
    params: Omit<fetchV1FarmsParams, 'masterChefAddressesV1' | 'multicall'>,
    masterChefAddressesV1: string,
  ) => {
    return farmV1FetchFarms({ ...params, multicall, masterChefAddressesV1 })
  }
  return {
    fetchFarmsV1,
    fetchMasterChefV1Data: (isTestnet: boolean, masterChefAddressesV1: string) =>
      _fetchMasterChefV1Data({ isTestnet, masterChefAddressesV1, multicall }),
    isChainSupported: (chainId: number) => supportedChainId.includes(chainId),
  }
}

export * from './types'
export * from './farmsPriceHelpers'
