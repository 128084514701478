import BigNumber from 'bignumber.js'
// import fromPairs from 'lodash/fromPairs'
// import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
// import nftsConfig from 'config/constants/nfts'
import statkeNftABI from 'config/abi/stakeNft.json'
// import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
// import { getAddress } from 'utils/addressHelpers'
// import { BIG_ZERO } from 'utils/bigNumber'
// import { getBalanceNumber } from 'utils/formatBalance'
import { TOKEN_TOP } from 'config/constants/endpoints'
import qs from 'qs'
// import { useStakeNftContract } from 'hooks/useContract'

// const stakeNftContract = useStakeNftContract()

// const nftsBalanceOf = nftsConfig.map((nftConfig) => {
//   return {
//     address: nftConfig.stakingToken.address,
//     name: 'balanceOf',
//     params: [getAddress(nftConfig.contractAddress)],
//   }
// })

export const fetchNftsGetPoolInfo = async (nftsConfig) => {
  const calls = nftsConfig.map((nftConfig) => {
    return {
      address: nftConfig.contractAddress,
      name: 'getPoolInfo',
      params: [nftConfig.sousId],
    }
  })

  // console.log('calls', calls)

  const getNftsInfo = await multicall(statkeNftABI, calls)

  /**
   * getNftsInfo
   * return [0] address NFT合约地址（ERC721）
   * return [1] address 收益代币地址（ERC20）
   * return [2] uint256 NFT质押的总数量
   * return [3] uint256 质押池的资金量
   * return [4] uint256 NFT质押的总权重（单位：个），放大100倍（展示除100）
   * return [5] string nft的名字
   * return [6] string 收益代币的名字
   *
   */

  // console.log('getNftsInfo===', getNftsInfo)

  return nftsConfig.map((p, index) => ({
    sousId: p.sousId,
    erc721Contract: getNftsInfo[index][0],
    totalPool: new BigNumber(getNftsInfo[index][3]?.toString()).toJSON(),
    totalStaked: new BigNumber(getNftsInfo[index][2]?.toString()).toJSON(),
  }))
}

// get nfts token prices
export const fetchNftsTokenPrices = async () => {
  const params = qs.stringify({
    page: 1,
    page_size: 50,
    order: 'volumeUSD',
    is_asc: false, // false代表倒序，true代表正序
  })
  let data = []
  try {
    const res = await fetch(`${TOKEN_TOP}?${params}`)
    const resData = await res.json()
    if (resData?.status === 200) {
      data = resData.data.content
      // console.log('fecth top tokens data===', resData?.data)
    }
  } catch (error) {
    data = []
    console.error('Unable to fetch NFT data:', error)
  }
  return data
}
