import type { Signer } from '@ethersproject/abstract-signer'
import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import type { Provider } from '@ethersproject/providers'
import { ChainId, Currency } from '@pancakeswap/sdk'
import { robin, robinMain } from '@pancakeswap/wagmi/chains'
import memoize from 'lodash/memoize'
import { TokenAddressMap } from '@pancakeswap/tokens'
// import { BASE_BSC_SCAN_URLS } from '../config'
import { chains } from './wagmi'

// returns the checksummed address if the address is valid, otherwise returns false
export const isAddress = memoize((value: any): string | false => {
  try {
    return getAddress(value)
  } catch {
    return false
  }
})

export function getBlockExploreLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainIdOverride?: number,
): string {
  // ChainId.ROBIN_TESTNET, ChainId.ROBIN
  const chainId = chainIdOverride || ChainId.ROBIN
  const chain = chains.find((c) => c.id === chainId)
  if (!chain) return robinMain.blockExplorers.default.url
  switch (type) {
    case 'transaction': {
      return `${chain.blockExplorers.default.url}/tx/${data}`
    }
    case 'token': {
      return `${chain.blockExplorers.default.url}/token/${data}`
    }
    case 'block': {
      return `${chain.blockExplorers.default.url}/block/${data}`
    }
    case 'countdown': {
      return `${chain.blockExplorers.default.url}/block/countdown/${data}`
    }
    default: {
      return `${chain.blockExplorers.default.url}/address/${data}`
    }
  }
}

export function getBlockExploreName(chainIdOverride?: number) {
  const chainId = chainIdOverride || ChainId.ROBIN_TESTNET || ChainId.ROBIN
  const chain = chains.find((c) => c.id === chainId)

  return chain?.blockExplorers?.default.name || 'EtherScan'
}

// export function getBscScanLinkForNft(collectionAddress: string, tokenId: string): string {
//   return `${BASE_BSC_SCAN_URLS[ChainId.BSC]}/token/${collectionAddress}?a=${tokenId}`
// }

// add 10%
export function calculateGasMargin(value: BigNumber, margin = 1000): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(margin))).div(BigNumber.from(10000))
}

// account is optional
export function getContract(address: string, ABI: any, signer?: Signer | Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, signer)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency?.isNative) return true
  return Boolean(currency?.isToken && defaultTokens[currency.chainId]?.[currency.address])
}

export function priceToFixed(price: number) {
  let value: number | string
  if (price >= 1) {
    value = parseFloat(price.toFixed(2))
  } else if (price > 0.0001) {
    value = parseFloat(price.toFixed(6))
  }

  if (price < 0.0001) {
    value = `< ${parseFloat(price.toFixed(4))}`
  } else if (price < 1) {
    value = parseFloat(price.toFixed(4))
  }

  if (price < 0) {
    return 0
  }

  return value
}

export function countDown(endTime: any, status?: number) {
  // 获取当前时间 nowTime
  const nowTime = Math.floor(Date.now() / 1000)
  // 获取指定时间 data?.end_time
  // 获取时间差
  const timeDiff = Math.floor(endTime - nowTime)
  // 获取还剩多少天
  let d: number | string = Math.floor(timeDiff / 3600 / 24)
  d = d < 10 ? `0${d}` : d
  // 获取还剩多少小时
  let h: number | string = Math.floor((timeDiff / 3600) % 24)
  h = h < 10 ? `0${h}` : h
  // 获取还剩多少分钟
  let m: number | string = Math.floor((timeDiff / 60) % 60)
  m = m < 10 ? `0${m}` : m
  // 获取还剩多少秒
  let s: number | string = timeDiff % 60
  s = s < 10 ? `0${s}` : s
  // 输出结果
  const result = status === 1 ? `${d} : ${h} : ${m} : ${s}` : `${d}D : ${h}H : ${m}M : ${s}S`
  return result
}

export function countDownProgress(startTime: number, endTime: number, defWidth: number) {
  // 获取当前时间 nowTime
  const nowTime = Math.floor(Date.now() / 1000)
  // 获取时间差
  const elapsedM = Math.floor(nowTime - startTime)
  const totalTime = Math.floor(endTime - startTime)

  let percents = elapsedM / totalTime
  if (percents >= 1.0) {
    percents = 1.0
  }
  const widthProgress = defWidth * percents
  return {
    widthProgress,
    percents,
  }
}

// 升序排列
export const sortby = (arr, key) => {
  return arr.sort((a, b) => {
    const a1 = a[key]
    const b1 = b[key]
    return a1 - b1
  })
}
