import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { provider } from 'utils/wagmi'
import { Contract } from '@ethersproject/contracts'
// import livePools, { getPoolConfig } from 'config/constants/pools'
// import { PoolCategory } from 'config/constants/types'
import { BUSD } from '@pancakeswap/tokens'

// Addresses
import {
  getAddress,
  getPancakeProfileAddress,
  getPancakeBunniesAddress,
  getBunnyFactoryAddress,
  getBunnySpecialAddress,
  getMasterChefAddress,
  // getMasterChefV1Address,
  getLimitOrderAddress,
  getClaimRefundAddress,
  getCakeVaultAddress,
  getMulticallAddress,
  getBunnySpecialCakeVaultAddress,
  getAnniversaryAchievement,
  getBunnySpecialXmasAddress,
  getZapAddress,
  // getCakeFlexibleSideVaultAddress,
  getBCakeFarmBoosterAddress,
  getBCakeFarmBoosterProxyFactoryAddress,
  getCreateAuctionAddress,
  getFarmBoxAddress,
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/pancakeProfile.json'
import pancakeBunniesAbi from 'config/abi/pancakeBunnies.json'
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import masterChef from 'config/abi/masterchef.json'
import masterChefV1 from 'config/abi/masterchefV1.json'
import limitOrderProtocol from 'config/abi/LimitOrderProtocol.json'
// import sousChef from 'config/abi/sousChef.json'
// import sousChefV2 from 'config/abi/sousChefV2.json'
// import sousChefBnb from 'config/abi/sousChefBnb.json'
import stakeTokenAbi from 'config/abi/stakeToken.json'

import claimRefundAbi from 'config/abi/claimRefund.json'
import cakeVaultV2Abi from 'config/abi/cakeVaultV2.json'
// import cakeFlexibleSideVaultV2Abi from 'config/abi/cakeFlexibleSideVaultV2.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import bunnySpecialCakeVaultAbi from 'config/abi/bunnySpecialCakeVault.json'
import bunnySpecialXmasAbi from 'config/abi/bunnySpecialXmas.json'
import anniversaryAchievementAbi from 'config/abi/anniversaryAchievement.json'
import erc721CollectionAbi from 'config/abi/erc721collection.json'
import zapAbi from 'config/abi/zap.json'
import bCakeFarmBoosterAbi from 'config/abi/bCakeFarmBooster.json'
import bCakeFarmBoosterProxyFactoryAbi from 'config/abi/bCakeFarmBoosterProxyFactory.json'
import bCakeProxyAbi from 'config/abi/bCakeProxy.json'
import createAuctionAbi from 'config/abi/createAuction.json'
import stakeNftAbi from 'config/abi/stakeNft.json'

// Types
import type {
  ChainlinkOracle,
  AnniversaryAchievement,
  Erc20,
  Erc721,
  Cake,
  BunnyFactory,
  PancakeBunnies,
  PancakeProfile,
  Masterchef,
  MasterchefV1,
  // SousChef,
  // SousChefV2,
  BunnySpecial,
  LpToken,
  ClaimRefund,
  Multicall,
  BunnySpecialCakeVault,
  Erc721collection,
  CakeVaultV2,
  // CakeFlexibleSideVaultV2,
  Zap,
  BCakeFarmBooster,
  BCakeFarmBoosterProxyFactory,
  BCakeProxy,
} from 'config/abi/types'
import { ChainId } from '@pancakeswap/sdk'

export const getContract = ({
  abi,
  address,
  chainId = ChainId.ROBIN,
  signer,
}: {
  abi: any
  address: string
  chainId?: ChainId
  signer?: Signer | Provider
}) => {
  const signerOrProvider = signer ?? provider({ chainId })
  // console.log('-----------------', address, abi, signerOrProvider)
  return new Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: bep20Abi, address, signer }) as Erc20
}
export const getErc721Contract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: erc721Abi, address, signer }) as Erc721
}
export const getLpContract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: lpTokenAbi, address, signer }) as LpToken
}
// export const getSouschefContract = (id: number, signer?: Signer | Provider) => {
//   const config = poolConfig.find((pool) => pool.sousId === id)
//   const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
//   return getContract({ abi, address: getAddress(config.contractAddress), signer }) as SousChef
// }
// export const getSouschefV2Contract = (id: number, signer?: Signer | Provider) => {
//   const config = poolConfig.find((pool) => pool.sousId === id)
//   return getContract({ abi: sousChefV2, address: getAddress(config.contractAddress), signer }) as SousChefV2
// }

export const getStakeTokenContract = (id: number, signer?: Signer | Provider) => {
  const poolsConfig = localStorage.getItem('poolsList') ? JSON.parse(localStorage.getItem('poolsList')) : []
  console.log('getStakeTokenContract-111111111111111111111111111111')
  // const pools = await getPoolConfig()
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = stakeTokenAbi
  return getContract({ abi, address: config.contractAddress, signer })
}

export const getFarmBoxContract = (signer?: Signer | Provider) => {
  return getContract({ abi: stakeTokenAbi, address: getFarmBoxAddress(), signer })
}

export const getCakeContract = (signer?: Signer | Provider, chainId?: number) => {
  return getContract({
    abi: cakeAbi,
    address: chainId ? BUSD[chainId].address : BUSD[ChainId.ROBIN_TESTNET].address,
    signer,
  }) as Cake
}
export const getProfileContract = (signer?: Signer | Provider) => {
  return getContract({ abi: profileABI, address: getPancakeProfileAddress(), signer }) as PancakeProfile
}
export const getPancakeBunniesContract = (signer?: Signer | Provider) => {
  return getContract({ abi: pancakeBunniesAbi, address: getPancakeBunniesAddress(), signer }) as PancakeBunnies
}
export const getBunnyFactoryContract = (signer?: Signer | Provider) => {
  return getContract({ abi: bunnyFactoryAbi, address: getBunnyFactoryAddress(), signer }) as BunnyFactory
}
export const getBunnySpecialContract = (signer?: Signer | Provider) => {
  return getContract({ abi: bunnySpecialAbi, address: getBunnySpecialAddress(), signer }) as BunnySpecial
}
export const getMasterchefContract = (signer?: Signer | Provider, chainId?: number) => {
  return getContract({ abi: masterChef, address: getMasterChefAddress(chainId), signer }) as Masterchef
}
export const getMasterchefV1Contract = (masterchefV1Address: string, signer?: Signer | Provider) => {
  return getContract({ abi: masterChefV1, address: masterchefV1Address, signer }) as MasterchefV1
}
export const getLimitOrderContract = (signer?: Signer | Provider) => {
  return getContract({ abi: limitOrderProtocol, address: getLimitOrderAddress(), signer })
}
export const getClaimRefundContract = (signer?: Signer | Provider) => {
  return getContract({ abi: claimRefundAbi, address: getClaimRefundAddress(), signer }) as ClaimRefund
}

export const getCakeVaultV2Contract = (signer?: Signer | Provider) => {
  return getContract({ abi: cakeVaultV2Abi, address: getCakeVaultAddress(), signer }) as CakeVaultV2
}

export const getCakeFlexibleSideVaultV2Contract = (signer?: Signer | Provider) => {
  return null
  // return getContract({
  //   abi: cakeFlexibleSideVaultV2Abi,
  //   address: getCakeFlexibleSideVaultAddress(),
  //   signer,
  // }) as CakeFlexibleSideVaultV2
}

export const getChainlinkOracleContract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: chainlinkOracleAbi, address, signer }) as ChainlinkOracle
}
export const getMulticallContract = (chainId: ChainId) => {
  return getContract({ abi: MultiCallAbi, address: getMulticallAddress(chainId), chainId }) as Multicall
}
export const getBunnySpecialCakeVaultContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: bunnySpecialCakeVaultAbi,
    address: getBunnySpecialCakeVaultAddress(),
    signer,
  }) as BunnySpecialCakeVault
}
export const getBunnySpecialXmasContract = (signer?: Signer | Provider) => {
  return getContract({ abi: bunnySpecialXmasAbi, address: getBunnySpecialXmasAddress(), signer })
}

export const getAnniversaryAchievementContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: anniversaryAchievementAbi,
    address: getAnniversaryAchievement(),
    signer,
  }) as AnniversaryAchievement
}

export const getErc721CollectionContract = (signer?: Signer | Provider, address?: string) => {
  return getContract({ abi: erc721CollectionAbi, address, signer }) as Erc721collection
}

export const getZapContract = (signer?: Signer | Provider) => {
  return getContract({ abi: zapAbi, address: getZapAddress(), signer }) as Zap
}

export const getBCakeFarmBoosterContract = (signer?: Signer | Provider) => {
  return getContract({ abi: bCakeFarmBoosterAbi, address: getBCakeFarmBoosterAddress(), signer }) as BCakeFarmBooster
}

export const getBCakeFarmBoosterProxyFactoryContract = (signer?: Signer | Provider) => {
  return getContract({
    abi: bCakeFarmBoosterProxyFactoryAbi,
    address: getBCakeFarmBoosterProxyFactoryAddress(),
    signer,
  }) as BCakeFarmBoosterProxyFactory
}

export const getBCakeProxyContract = (proxyContractAddress: string, signer?: Signer | Provider) => {
  return getContract({ abi: bCakeProxyAbi, address: proxyContractAddress, signer }) as BCakeProxy
}

export const getCreateAuctionContract = (signer?: Signer | Provider) => {
  return getContract({ abi: createAuctionAbi, address: getCreateAuctionAddress(), signer })
}

export const getStakeNftContract = (address: string, signer?: Signer | Provider) => {
  return getContract({ abi: stakeNftAbi, address, signer })
}
