import { createAsyncThunk, createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'
// import BigNumber from 'bignumber.js'
import fromPairs from 'lodash/fromPairs'
import { getNftConfig } from 'config/constants/nfts'
import { NftsState, SerializedPool } from 'state/types'
import { SerializedPoolConfig } from 'config/constants/types'
// import { multicallv2 } from 'utils/multicall'
// import { robTestnetTokens } from '@pancakeswap/tokens'
// import { getBalanceNumber } from 'utils/formatBalance'
// import { rpgRpcProvider } from 'utils/providers'
import { fetchNftsGetPoolInfo, fetchNftsTokenPrices } from './fetchNfts'
import {
  // fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
} from './fetchNftsUser'
import { getTokenPricesFromTopToken } from './helpers'
import { resetUserState } from '../global/actions'

export const initialPoolVaultState = Object.freeze({
  totalShares: null,
  totalLockedAmount: null,
  pricePerFullShare: null,
  totalCakeInVault: null,
  fees: {
    performanceFee: null,
    withdrawalFee: null,
    withdrawalFeePeriod: null,
  },
  userData: {
    isLoading: true,
    userShares: null,
    cakeAtLastUserAction: null,
    lastDepositedTime: null,
    lastUserActionTime: null,
    credit: null,
    locked: null,
    lockStartTime: null,
    lockEndTime: null,
    userBoostedShare: null,
    lockedAmount: null,
    currentOverdueFee: null,
    currentPerformanceFee: null,
  },
  creditStartBlock: null,
})

export const initialIfoState = Object.freeze({
  credit: null,
  ceiling: null,
})

const initialState: NftsState = {
  data: [],
  userDataLoaded: false,
}

// Async thunks
export const fetchInitialNftsData = createAsyncThunk<SerializedPoolConfig[], { chainId: number }>(
  'pool/fetchInitialNftsData',
  async ({ chainId }) => {
    const nftDataList = await getNftConfig()
    console.log('nft------------------fetchInitialNftsData111')
    return nftDataList
  },
)

export const fetchNftsPublicDataAsync =
  (currentBlockNumber: number, chainId: number, nftsConfig: SerializedPoolConfig[]) => async (dispatch, getState) => {
    try {
      console.log('nft------------------fetchNftsPublicDataAsync222')
      const [getPoolInfo, nftsTokenPrices] = await Promise.all([
        fetchNftsGetPoolInfo(nftsConfig),
        // currentBlockNumber ? Promise.resolve(currentBlockNumber) : rpgRpcProvider.getBlockNumber(),
        fetchNftsTokenPrices(),
      ])

      const totalStakingsSousIdMap = fromPairs(getPoolInfo.map((entry) => [entry.sousId, entry]))

      const prices = getTokenPricesFromTopToken([...nftsTokenPrices])
      // console.log('nftsTokenPrices', nftsConfig)

      const liveData = nftsConfig.map((nft) => {
        const totalStaking: any = totalStakingsSousIdMap[nft.sousId]
        // console.log('totalStaking', totalStaking)

        const stakingTokenAddress = nft.stakingToken.address ? nft.stakingToken.address.toLowerCase() : null
        const stakingTokenPrice = stakingTokenAddress && prices[stakingTokenAddress] ? prices[stakingTokenAddress] : 0

        const earningTokenAddress = nft.earningToken.address ? nft.earningToken.address.toLowerCase() : null
        const earningTokenPrice = earningTokenAddress && prices[earningTokenAddress] ? prices[earningTokenAddress] : 0

        return {
          ...totalStaking,
          stakingTokenPrice,
          earningTokenPrice,
        }
      })

      dispatch(setNftsPublicData(liveData))
    } catch (error) {
      console.error('[Nfts Action] error when getting public data', error)
    }
  }

export const fetchNftsUserDataAsync = createAsyncThunk<
  { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; pendingReward: any }[],
  string
>('nft/fetchPoolsUserData', async (account, { rejectWithValue }) => {
  console.log('nft------------------nft/fetchPoolsUserData333')
  try {
    const nftsConfig = localStorage.getItem('nftsList') ? JSON.parse(localStorage.getItem('nftsList')) : []
    const [
      // allowances,
      // stakingTokenBalances,
      // stakedBalances,
      pendingRewards,
    ] = await Promise.all([
      // fetchPoolsAllowance(account),
      // fetchUserBalances(account),
      // fetchUserStakeBalances(account),
      fetchUserPendingRewards(account, nftsConfig),
    ])

    const userData = nftsConfig.map((nft) => ({
      sousId: nft.sousId,
      allowance: '0', // allowances[nft.sousId],
      stakingTokenBalance: '0', // stakingTokenBalances[nft.sousId],
      stakedBalance: '0', // stakedBalances[nft.sousId],
      pendingReward: pendingRewards[nft.sousId],
    }))
    // console.log('fetchPoolsUserData', stakedBalances, userData)
    return userData
  } catch (e) {
    return rejectWithValue(e)
  }
})

// export const updateUserAllowance = createAsyncThunk<
//   { sousId: number; field: string; value: any },
//   { sousId: number; account: string }
// >('nft/updateUserAllowance', async ({ sousId, account }) => {
//   const allowances = await fetchPoolsAllowance(account)
//   return { sousId, field: 'allowance', value: allowances[sousId] }
// })

export const updateUserBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; nftsConfig: any }
>('nft/updateUserBalance', async ({ sousId, account, nftsConfig }) => {
  const tokenBalances = await fetchUserBalances(account, nftsConfig)
  return { sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }
})

export const updateUserStakedBalance = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; nftsConfig: any }
>('nft/updateUserStakedBalance', async ({ sousId, account, nftsConfig }) => {
  const stakedBalances = await fetchUserStakeBalances(account, nftsConfig)
  return { sousId, field: 'getUserInfo', value: stakedBalances[sousId] }
})

export const updateUserPendingReward = createAsyncThunk<
  { sousId: number; field: string; value: any },
  { sousId: number; account: string; nftsConfig: any }
>('nft/updateUserPendingReward', async ({ sousId, account, nftsConfig }) => {
  const pendingRewards = await fetchUserPendingRewards(account, nftsConfig)
  return { sousId, field: 'pendingCake', value: pendingRewards[sousId] }
})

export const NftsSlice = createSlice({
  name: 'Nfts',
  initialState,
  reducers: {
    setNftPublicData: (state, action) => {
      const { sousId } = action.payload
      const nftIndex = state.data.findIndex((nft) => nft.sousId === sousId)
      state.data[nftIndex] = {
        ...state.data[nftIndex],
        ...action.payload.data,
      }
    },
    setNftUserData: (state, action) => {
      const { sousId } = action.payload
      state.data = state.data.map((nft) => {
        if (nft.sousId === sousId) {
          return { ...nft, userDataLoaded: true, userData: action.payload.data }
        }
        return nft
      })
    },
    setNftsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      const livePoolsSousIdMap = fromPairs(livePoolsData.map((entry) => [entry.sousId, entry]))
      state.data = state.data.map((nft) => {
        const livePoolData = livePoolsSousIdMap[nft.sousId]
        return { ...nft, ...livePoolData }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUserState, (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state.data = state.data.map(({ userData, ...nft }) => {
        return { ...nft }
      })
      state.userDataLoaded = false
    })
    builder.addCase(fetchInitialNftsData.fulfilled, (state, action) => {
      const nftData = action.payload
      state.data = nftData
    })
    builder.addCase(
      fetchNftsUserDataAsync.fulfilled,
      (
        state,
        action: PayloadAction<
          { sousId: number; allowance: any; stakingTokenBalance: any; stakedBalance: any; pendingReward: any }[]
        >,
      ) => {
        const userData = action.payload
        const userDataSousIdMap = fromPairs(userData.map((entry) => [entry.sousId, entry]))
        state.data = state.data.map((nft) => ({
          ...nft,
          userDataLoaded: true,
          userData: userDataSousIdMap[nft.sousId],
        }))
        state.userDataLoaded = true
      },
    )
    builder.addCase(fetchNftsUserDataAsync.rejected, (state, action) => {
      console.error('[Nfts Action] Error fetching nft user data', action.payload)
    })
    builder.addMatcher(
      isAnyOf(
        // updateUserAllowance.fulfilled,
        updateUserBalance.fulfilled,
        updateUserStakedBalance.fulfilled,
        updateUserPendingReward.fulfilled,
      ),
      (state, action: PayloadAction<{ sousId: number; field: string; value: any }>) => {
        const { field, value, sousId } = action.payload
        const index = state.data.findIndex((p) => p.sousId === sousId)

        if (index >= 0) {
          state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
        }
      },
    )
  },
})

// Actions
export const { setNftsPublicData, setNftPublicData, setNftUserData } = NftsSlice.actions

export default NftsSlice.reducer
