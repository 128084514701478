import {
  MenuItemsType,
  // DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  ResourcesIcon,
  ResourcesFilledIcon,
  NftIcon,
  NftFillIcon,
  // MoreIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
// import { SUPPORT_ONLY_RIN } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (t: ContextApi['t'], isDark: boolean, languageCode?: string, chainId?: number) => ConfigMenuItemsType[] =
  (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Trade'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
      },
      {
        label: t('Limit'),
        icon: TrophyIcon,
        fillIcon: TrophyFillIcon,
        href: '/limit-orders',
        showItemsOnMobile: false,
      },
      {
        label: t('Liquidity'),
        icon: NftIcon,
        fillIcon: NftFillIcon,
        href: '/liquidity',
        showItemsOnMobile: false,
      },
      {
        label: t('Mining'),
        href: '/farms',
        icon: EarnIcon,
        fillIcon: EarnFillIcon,
        // supportChainIds: SUPPORT_ONLY_RIN,
        image: '/images/decorations/pe2.png',
        items: [
          {
            label: t('Farms'),
            href: '/farms',
          },
          {
            label: t('Pools'),
            href: '/pools',
          },
          {
            label: t('NFTs'),
            href: '/nfts',
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      // {
      //   label: t('IDO'),
      //   href: '/ido',
      //   icon: ResourcesIcon,
      //   fillIcon: ResourcesFilledIcon,
      //   showItemsOnMobile: false,
      // },
      // {
      //   label: t('Voting'),
      //   href: '/voting',
      //   icon: ResourcesIcon,
      //   fillIcon: ResourcesFilledIcon,
      //   showItemsOnMobile: false,
      // },
      {
        label: t('Info'),
        href: '/info',
        icon: ResourcesIcon,
        fillIcon: ResourcesFilledIcon,
        showItemsOnMobile: false,
      },
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
