import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var baseColors = {
    failure: "#ff3141",
    primary: "#333",
    primaryBright: "#53DEE9",
    primaryDark: "#0098A1",
    secondary: "#7399f7",
    success: "#00C3FF",
    warning: "#ff3141",
    arrowUp: "#78C326",
    arrowDown: "#FF4444",
    icon: "#002236"
};
export var additionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D"
};
export var lightColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    tabs: "#EAECEF",
    tabBackground: "#EAECEF",
    selectCurrency: "#EAECEF",
    gradient: "linear-gradient(270deg,#50D0B7 0%,#00C3FF 100%)",
    btnColor: "#fff",
    btnColor2: "#333",
    nftBackground: "#EAECEF",
    nftBoxshadow: "2px 2px 4px 0px rgba(195,195,195,0.5)",
    nftColor: "#fff",
    backgroundDisabled: "#E9EAEB",
    skeletonBackground: "#E9EAEB",
    background: "#fff",
    inputBackground: "#fff",
    btnBackground: "#F8F8F8",
    btnBoxShadow: "2px 2px 4px 0px rgba(187,187,187,0.5), -2px -2px 4px 0px #FFFFFF",
    borderBoxShadow: "5px 5px 10px 0px rgba(195,195,195,0.5), -5px -5px 10px 0px #FFFFFF",
    borderBoxShadowInset: "1px 2px 2px 0px #F1F1F1",
    coinBtnBoxShadow: "3px 3px 6px 0px #E1E1E1, -3px -3px 6px 0px #FFFFFF",
    maxBtnBoxShadow: "1px 1px 3px 0px #E1E1E1, -1px -1px 3px 0px #FFFFFF",
    searchBoxShadow: "inset -2px -2px 3px 0px #ffffff, inset 1px 1px 3px 0px rgba(195, 195, 195, 0.5)",
    farmsBoxShadow: "4px 4px 12px 0px rgba(195, 195, 195, 0.7), -2px -2px 3px 0px #ffffff",
    harvestBtnBoxShadow: "inset 2px 2px 3px 0px rgba(195,195,195,0.5), inset -2px -2px 3px 0px #FFFFFF",
    backgroundAlt: "#F8F7F7",
    backgroundAlt2: "rgba(255, 255, 255, 0.7)",
    backgroundAlt3: "#F3F4F7",
    backgroundTransparent: "rgba(255, 255, 255, 0.5)",
    cardBorder: "#dbdce0",
    cardBorder2: "#DEDEDE",
    contrast: "#191326",
    dropdown: "#F6F6F6",
    dropdownDeep: "#EEEEEE",
    invertedContrast: "#FFFFFF",
    input: "#ffffff",
    inputOrders: "#F5F5F5",
    inputSecondary: "#CFCFCF",
    fromChart: "#D9DDF8",
    toChart: "#BBC4FC",
    lineChart: "#0A4AE8",
    tertiary: "#ffffff",
    text: "#002237",
    from: "#4B5871",
    subTitle: "#4B5871",
    mask: "rgba(0, 0, 0, .5)",
    inputTxt: "#333",
    textDisabled: "#f9f9f9",
    textSubtle: "#333",
    textMenuMobile: "#333",
    disabled: "#E9EAEB",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
        cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)",
        blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
        violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
        violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    },
    toggleActive: "#99cba6"
});
export var darkColors = _object_spread_props(_object_spread({}, baseColors, additionalColors), {
    // secondary: "#9A6AFF",
    tabs: "#2B2D31",
    tabBackground: "#4E5058",
    selectCurrency: "#3F4249",
    gradient: "linear-gradient(270deg,#50D0B7 0%,#00C3FF 100%)",
    btnColor: "#333",
    btnColor2: "#fff",
    background: "#fff",
    inputBackground: "#32353A",
    nftBackground: "#4E5058",
    nftBoxshadow: "5px 5px 17px 0px rgba(0,0,0,0.4), -5px -5px 10px 0px #757575",
    nftColor: "#333",
    btnBackground: "#5D5B5F",
    btnBoxShadow: "5px 5px 17px 0px rgba(0,0,0,0.4), -5px -5px 10px 0px #757575",
    borderBoxShadow: "5px 5px 17px 0px rgba(0,0,0,0.5), -5px -5px 10px 0px #6E6E6E",
    borderBoxShadowInset: "inset 1px 1px 0px 0px rgba(68,68,68,0.5);",
    coinBtnBoxShadow: "3px 3px 6px 0px rgba(0,0,0,0.5), -3px -3px 6px 0px #6E6E6E",
    maxBtnBoxShadow: "1px 1px 3px 0px rgba(0,0,0,0.5), -1px -1px 3px 0px #6E6E6E",
    searchBoxShadow: "inset -2px -2px 3px 0px #6E6E6E, inset 1px 1px 3px 0px rgba(195, 195, 195, 0.5)",
    farmsBoxShadow: "4px 4px 12px 0px rgba(0,0,0,0.5), -2px -2px 3px 0px #6E6E6E",
    harvestBtnBoxShadow: "inset 2px 2px 3px 0px rgba(195,195,195,0.5), inset -2px -2px 3px 0px #6E6E6E",
    backgroundDisabled: "#2B2D31",
    skeletonBackground: "rgba(255, 255, 255, 0.1)",
    backgroundAlt: "#1E1F22",
    backgroundAlt2: "rgba(39, 38, 44, 0.7)",
    backgroundAlt3: "#1E1F22",
    backgroundTransparent: "rgba(39, 38, 44, 0.5)",
    cardBorder: "#524F54",
    cardBorder2: "#393939",
    contrast: "#FFFFFF",
    dropdown: "#5D5B5F",
    dropdownDeep: "#100C18",
    invertedContrast: "#5D5B5F",
    input: "#2B2D31",
    inputOrders: "#5D5B5F",
    inputSecondary: "#5D5B5F",
    fromChart: "#D9DDF8",
    toChart: "#BBC4FC",
    lineChart: "#0A4AE8",
    primary: "#fff",
    success: "#F4EEFF",
    primaryDark: "#0098A1",
    tertiary: "#3F4349",
    text: "#FFFFFF",
    from: "#C3C9D0",
    subTitle: "#999",
    mask: "rgba(0, 0, 0, .5)",
    inputTxt: "#D6D6D6",
    textDisabled: "#2B2D31",
    textSubtle: "#D6D6D6",
    textMenuMobile: "#F5FBF2",
    disabled: "#3F4349",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
        cardHeader: "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
        blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
        violet: "linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)",
        violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    },
    toggleActive: "#99cba6"
});
