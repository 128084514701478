import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { VaultKey } from 'state/types'

export const getAddress = (address: Address, chainId?: number): string => {
  return address[chainId] ? address[chainId] : address[ChainId.ROBIN]
}

export const getMasterChefAddress = (chainId?: number) => {
  return getAddress(addresses.masterChef, chainId)
}
// export const getMasterChefV1Address = () => {
//   return getAddress(addresses.masterChefV1)
// }
export const getLimitOrderAddress = () => {
  return getAddress(addresses.limitOrderV1)
}
export const getMulticallAddress = (chainId?: number) => {
  return getAddress(addresses.multiCall, chainId)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeBunniesAddress = () => {
  return getAddress(addresses.pancakeBunnies)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}

export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey])
}

export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}

export const getCakeFlexibleSideVaultAddress = () => {
  return getAddress(addresses.cakeFlexibleSideVault)
}

export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialXmasAddress = () => {
  return getAddress(addresses.bunnySpecialXmas)
}

export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}

export const getZapAddress = () => {
  return getAddress(addresses.zap)
}
export const getICakeAddress = () => {
  return getAddress(addresses.iCake)
}

export const getBCakeFarmBoosterAddress = () => {
  return getAddress(addresses.bCakeFarmBooster)
}

export const getBCakeFarmBoosterProxyFactoryAddress = () => {
  return getAddress(addresses.bCakeFarmBoosterProxyFactory)
}

export const getCreateAuctionAddress = () => {
  return getAddress(addresses.createAuction)
}

export const getVoteGovernorAddress = () => {
  return getAddress(addresses.voteGovernor)
}

export const getVotesTokenAddress = () => {
  return getAddress(addresses.votesToken)
}

// export const getNftStakeAddress = () => {
//   return getAddress(addresses.nftStake)
// }

// export const getCakeStakeAddress = () => {
//   return getAddress(addresses.cakeTokenV1)
// }

// export const getRpgStakeAddress = () => {
//   return getAddress(addresses.rpgTokenV1)
// }

export const getFarmBoxAddress = () => {
  return getAddress(addresses.farmBox)
}
