import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: block;\n  border: 0;\n  background: transparent;\n  cursor: pointer;\n  height: 48px;\n  padding: 4px 0;\n  // &:hover div {\n  //   background: ",
        ";\n  // -webkit-background-clip: text;\n  // -webkit-text-fill-color: ",
        ";\n  // }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: -webkit-box;\n  overflow: hidden;\n  user-select: none;\n  -webkit-line-clamp: 1;\n  -webkit-box-orient: vertical;\n  -webkit-user-select: none;\n  -webkit-touch-callout: none;\n  background: ",
        ";\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { Text } from "../Text";
export var StyledBottomNavItem = styled.button.withConfig({
    componentId: "sc-10d79473-0"
})(_templateObject(), function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "linear-gradient(270deg, #50D0B7 0%, #00C3FF 100%)" : "none";
}, function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "transparent" : "initial";
});
export var StyledBottomNavText = styled(Text).withConfig({
    componentId: "sc-10d79473-1"
})(_templateObject1(), function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "linear-gradient(270deg, #50D0B7 0%, #00C3FF 100%)" : "none";
}, function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "transparent" : "initial";
});
