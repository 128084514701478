import { useMemo } from 'react'
// import { menuStatus } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTheme } from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
// import { useMenuItemsStatus } from './useMenuItemsStatus'
import config, { ConfigMenuItemsType } from '../config/config'

export const useMenuItems = (): ConfigMenuItemsType[] => {
  const {
    t,
    currentLanguage: { code: languageCode },
  } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const { isDark } = useTheme()
  // const menuItemsStatus = useMenuItemsStatus()

  const menuItems = useMemo(() => {
    return config(t, isDark, languageCode, chainId)
  }, [t, isDark, languageCode, chainId])

  return useMemo(() => {
    // console.log("顶部导航菜单===", menuItems, menuStatus)
    if (menuItems && Object.keys(menuItems).length) {
      return menuItems.map((item) => {
        const innerItems = item?.items?.map((innerItem) => {
          return innerItem
        })
        // const innerItems = item?.items?.map((innerItem) => {
        //   const itemStatus = menuItemsStatus[innerItem.href]
        //   if (itemStatus) {
        //     let itemMenuStatus
        //     if (itemStatus === 'soon') {
        //       itemMenuStatus = menuStatus.SOON
        //     } else if (itemStatus === 'live') {
        //       itemMenuStatus = menuStatus.LIVE
        //     } else {
        //       itemMenuStatus = menuStatus.NEW
        //     }
        //     return { ...innerItem, status: itemMenuStatus }
        //   }
        //   return innerItem
        // })
        return { ...item, items: innerItems }
      })
    }
    return menuItems
  }, [menuItems])
}
