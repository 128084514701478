import { Language } from '@pancakeswap/uikit'

export const EN: Language = { locale: 'en-US', language: 'EN', code: 'en' }
// export const ZHCN: Language = { locale: "zh-CN", language: "中文", code: "中文" }
export const ZHTW: Language = { locale: 'zh-TW', language: '繁體中文', code: '中文' }

export const languages = {
  'en-US': EN,
  // "zh-CN": ZHCN,
  'zh-TW': ZHTW,
}

const languageList = Object.values(languages)

export default languageList
